@import url("../_variables.css");

/* ////////// Canvas ////////// */

.container-canvas {
    background-color: var(--color-outside-canvas);
}
#canvas {
    margin-top: 45px;
    margin-left: 45px;
    background-color: var(--color-white); 
    border: 1px solid var(--color-black); 
    /* border-top: none; */
    z-index: 5;
}