@import url("../_variables.css");

.disabled {
    display: none;
}
.divider {
    width: 100%;
    border-bottom: 1px solid var(--color-hover); 
    margin: 1rem 0;
}

/* ////////// Top Navbar ////////// */

.toolbar-top {
    width: 100%;
    height: 45px;
    background: var(--color-bg);
    display: flex;
    flex-direction: row;
    position: absolute;
    align-items: center;
    z-index: 5;
}
.toolbar-top input {
    display: none;
}
.toolbar-top label {
    /* max-width: 45px; */
    font-weight: 300;
    font-size: 15px;
    filter: invert();
    padding: 10px 10px 10px 10px; 
    cursor: pointer;
    display: flex;
    flex-direction: row;
}
.toolbar-top label:hover { 
    filter: none;
    color: var(--color-white);
    background: var(--color-hover);
}
.toolbar-top label i {
    font-size: 20px;
    padding-right: 0.3rem;
}
.toolbar-left .selected { 
    filter: none;
    color: var(--color-white);
    background-color: var(--color-hover);
}


/* ////////// Left Navbar ////////// */


.toolbar-left {
    height: 100vh;
    width: 45px;
    background-color: var(--color-bg);
    display: flex;
    flex-direction: column;
    float: left;
    position: absolute;
    align-items: center;
    border-top: 1px solid var(--color-hover);
    z-index: 4;
}
.toolbar-left label:first-of-type {
    margin-top: 45px;
    border-top: 1px solid rgb(192, 194, 209);
}
.toolbar-left input {
    display: none;
}
.toolbar-left label {
    max-width: 45px;
    font-weight: 300;
    font-size: 15px;
    filter: invert();
    padding: 10px 10px 10px 10px; 
    cursor: pointer;
}
.toolbar-left label:hover { 
    filter: none;
    color: var(--color-white);
    background: var(--color-hover);
}
.toolbar-left label:hover:first-of-type {
    border-top: 1px solid rgb(61, 61, 66);
}
.toolbar-left label i {
    font-size: 20px;
    padding-right: 0.3rem;
}
/* .toolbar-left .selected {
    background-color: var(--color-hover);
} */
/* .toolbar-left .disabled {
    display: none;
} */



/* ////////// Slider / Tool Size ////////// */

.tool-settings {
    border-top: 1px solid var(--color-hover);
    padding: 10px 0 ;
}
.tool-settings input {
   display: block;
}
.colorPickerArea { 
    width: 30px;
    height: 30px;
    margin: 5px;
    /* margin-bottom: 5px; */
    background-color: #fff;
    border-radius: 20px;
    cursor: pointer;
}
.tool-size {
    display: flex;
    flex-direction: column;
    /* justify-content: center; */
    align-items: center;
    
}
.tool-size input{
    margin: 10px;
    display: inline;
    writing-mode: bt-lr; /* IE */
    -webkit-appearance: slider-vertical; /* WebKit */
    cursor: pointer;
}
.tool-size div{
    color: var(--color-white);
}


/* ////////// Color Picker ////////// */

.brush-wrapper {
    display: flex;
    flex-direction: column;
    color: var(--color-text); 
    align-items: center;
    width: 100%;
}
.brush-wrapper div, .brush-wrapper span, .brush-wrapper input {
    margin-top: 1rem; 
}
.brush-wrapper:hover {
    /* background-color: var(--color-hover); */
}   
.brush-wrapper #current-brush-size {
    align-self: center;
} 