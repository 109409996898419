
@import url("../_variables.css");

/* ////////// Welcome menu ////////// */
.welcome-popup {
    width: 100%;
    height: 100vh;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1001;
    /* Change to "block" to activate the welcome popup  */
    display: none;
}
.popup-bg {
    background: #0008;
    z-index: 1000;
}
#popup-box {
    text-align: center;
    width: 70%;
    height: 70%;
    background: #fff;
    box-sizing: border-box;
    padding: 2rem;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    box-shadow: 1px 2px 5px 3px black;
}
.close-section {
    /* background-color: #0008; */
    position: absolute;
    padding: 5px 15px 5px 10px;
    top: 0;
    right: 0;
}
.close { 
    font-size: 1.8rem;
    color: var(--color-blue);
    transform: rotate(-45deg);
    cursor: pointer;
    /* background-color: blue; */
}
.close p {
    transition: transform 0.3s ease-in-out; 
}
.close-section:hover p {
    color: var(--color-orange);
    transform: scale(2);
    transition: all 0.3s ease-in-out;
}
#dont-show-this {
    width: 100%;
    height: 100%;
    cursor: pointer;
}
#close-window {
    cursor: pointer;
    border: 2px solid var(--color-hover);
    border-radius: 10%;
    text-decoration: none;
    margin-top: 15px;
    font-size: 1rem;
    text-align: center;
    color: var(--color-text); 
}
.popup-text h1 {
    padding: 1rem;
}
.popup-close {
    display: flex;
    justify-content: center;
    margin: 2rem;
}
.popup-close button { 
    background-color: var(--color-blue);
    text-decoration: none;
    border: 3px solid var(--color-blue);
    border-radius: 2%;
    position: absolute;
    bottom: 10%;
}
.popup-close button .btn {
    /* margin-top: 15px; */
    padding: .5rem;
    font-size: 1rem;
    text-align: center;
    color: var(--color-black); 
}
.popup-close button:hover {
    border: 3px solid var(--color-orange);
    background-color: var(--color-orange);
}