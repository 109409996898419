@import url(https://fonts.googleapis.com/css2?family=DM+Mono:ital,wght@0,300;0,400;0,500;1,500&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* ////////// All ////////// */

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: var(--font-fam);
}
.container {
  max-width: 100%;
  margin: auto;
  font-family: var(--font-fam);
}



/* .App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
} */


/* Import DM Mono font */

/* Colors */
:root {
    --color-bg: #27272d;
    --color-border: #4e5664;
    --color-white: #fff;
    --color-black: #000000;
    --color-hover: #37373f;
    --color-text: #98989b;
    --color-slider: #407693;
    --color-slider-light: #cee7f5;
    --color-slider-dark: #36657e;
    --color-disabled: rgb(32, 32, 32);

    --color-blue: #4e9bd4;
    --color-orange: #f16723;
    --color-yellow: #fbd20a;
    --color-pink: #f05f88;
    --color-beige: #ffe4b8;

    --color-outside-canvas: rgb(186, 213, 228);
}
/* Fonts */
:root {
    --font-fam: 'DM Mono', monospace;
}
/* ////////// Canvas ////////// */

.container-canvas {
    background-color: var(--color-outside-canvas);
}
#canvas {
    margin-top: 45px;
    margin-left: 45px;
    background-color: var(--color-white); 
    border: 1px solid var(--color-black); 
    /* border-top: none; */
    z-index: 5;
}
.disabled {
    display: none;
}
.divider {
    width: 100%;
    border-bottom: 1px solid var(--color-hover); 
    margin: 1rem 0;
}

/* ////////// Top Navbar ////////// */

.toolbar-top {
    width: 100%;
    height: 45px;
    background: var(--color-bg);
    display: flex;
    flex-direction: row;
    position: absolute;
    align-items: center;
    z-index: 5;
}
.toolbar-top input {
    display: none;
}
.toolbar-top label {
    /* max-width: 45px; */
    font-weight: 300;
    font-size: 15px;
    -webkit-filter: invert();
            filter: invert();
    padding: 10px 10px 10px 10px; 
    cursor: pointer;
    display: flex;
    flex-direction: row;
}
.toolbar-top label:hover { 
    -webkit-filter: none; 
            filter: none;
    color: var(--color-white);
    background: var(--color-hover);
}
.toolbar-top label i {
    font-size: 20px;
    padding-right: 0.3rem;
}
.toolbar-left .selected { 
    -webkit-filter: none; 
            filter: none;
    color: var(--color-white);
    background-color: var(--color-hover);
}


/* ////////// Left Navbar ////////// */


.toolbar-left {
    height: 100vh;
    width: 45px;
    background-color: var(--color-bg);
    display: flex;
    flex-direction: column;
    float: left;
    position: absolute;
    align-items: center;
    border-top: 1px solid var(--color-hover);
    z-index: 4;
}
.toolbar-left label:first-of-type {
    margin-top: 45px;
    border-top: 1px solid rgb(192, 194, 209);
}
.toolbar-left input {
    display: none;
}
.toolbar-left label {
    max-width: 45px;
    font-weight: 300;
    font-size: 15px;
    -webkit-filter: invert();
            filter: invert();
    padding: 10px 10px 10px 10px; 
    cursor: pointer;
}
.toolbar-left label:hover { 
    -webkit-filter: none; 
            filter: none;
    color: var(--color-white);
    background: var(--color-hover);
}
.toolbar-left label:hover:first-of-type {
    border-top: 1px solid rgb(61, 61, 66);
}
.toolbar-left label i {
    font-size: 20px;
    padding-right: 0.3rem;
}
/* .toolbar-left .selected {
    background-color: var(--color-hover);
} */
/* .toolbar-left .disabled {
    display: none;
} */



/* ////////// Slider / Tool Size ////////// */

.tool-settings {
    border-top: 1px solid var(--color-hover);
    padding: 10px 0 ;
}
.tool-settings input {
   display: block;
}
.colorPickerArea { 
    width: 30px;
    height: 30px;
    margin: 5px;
    /* margin-bottom: 5px; */
    background-color: #fff;
    border-radius: 20px;
    cursor: pointer;
}
.tool-size {
    display: flex;
    flex-direction: column;
    /* justify-content: center; */
    align-items: center;
    
}
.tool-size input{
    margin: 10px;
    display: inline;
    -webkit-writing-mode: bt-lr;
        -ms-writing-mode: bt-lr;
            writing-mode: bt-lr; /* IE */
    -webkit-appearance: slider-vertical; /* WebKit */
    cursor: pointer;
}
.tool-size div{
    color: var(--color-white);
}


/* ////////// Color Picker ////////// */

.brush-wrapper {
    display: flex;
    flex-direction: column;
    color: var(--color-text); 
    align-items: center;
    width: 100%;
}
.brush-wrapper div, .brush-wrapper span, .brush-wrapper input {
    margin-top: 1rem; 
}
.brush-wrapper:hover {
    /* background-color: var(--color-hover); */
}   
.brush-wrapper #current-brush-size {
    align-self: center;
} 

/* ////////// Welcome menu ////////// */
.welcome-popup {
    width: 100%;
    height: 100vh;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1001;
    /* Change to "block" to activate the welcome popup  */
    display: none;
}
.popup-bg {
    background: #0008;
    z-index: 1000;
}
#popup-box {
    text-align: center;
    width: 70%;
    height: 70%;
    background: #fff;
    box-sizing: border-box;
    padding: 2rem;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    box-shadow: 1px 2px 5px 3px black;
}
.close-section {
    /* background-color: #0008; */
    position: absolute;
    padding: 5px 15px 5px 10px;
    top: 0;
    right: 0;
}
.close { 
    font-size: 1.8rem;
    color: var(--color-blue);
    transform: rotate(-45deg);
    cursor: pointer;
    /* background-color: blue; */
}
.close p {
    transition: transform 0.3s ease-in-out; 
}
.close-section:hover p {
    color: var(--color-orange);
    transform: scale(2);
    transition: all 0.3s ease-in-out;
}
#dont-show-this {
    width: 100%;
    height: 100%;
    cursor: pointer;
}
#close-window {
    cursor: pointer;
    border: 2px solid var(--color-hover);
    border-radius: 10%;
    text-decoration: none;
    margin-top: 15px;
    font-size: 1rem;
    text-align: center;
    color: var(--color-text); 
}
.popup-text h1 {
    padding: 1rem;
}
.popup-close {
    display: flex;
    justify-content: center;
    margin: 2rem;
}
.popup-close button { 
    background-color: var(--color-blue);
    text-decoration: none;
    border: 3px solid var(--color-blue);
    border-radius: 2%;
    position: absolute;
    bottom: 10%;
}
.popup-close button .btn {
    /* margin-top: 15px; */
    padding: .5rem;
    font-size: 1rem;
    text-align: center;
    color: var(--color-black); 
}
.popup-close button:hover {
    border: 3px solid var(--color-orange);
    background-color: var(--color-orange);
}
