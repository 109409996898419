
/* Import DM Mono font */
@import url('https://fonts.googleapis.com/css2?family=DM+Mono:ital,wght@0,300;0,400;0,500;1,500&display=swap');

/* Colors */
:root {
    --color-bg: #27272d;
    --color-border: #4e5664;
    --color-white: #fff;
    --color-black: #000000;
    --color-hover: #37373f;
    --color-text: #98989b;
    --color-slider: #407693;
    --color-slider-light: #cee7f5;
    --color-slider-dark: #36657e;
    --color-disabled: rgb(32, 32, 32);

    --color-blue: #4e9bd4;
    --color-orange: #f16723;
    --color-yellow: #fbd20a;
    --color-pink: #f05f88;
    --color-beige: #ffe4b8;

    --color-outside-canvas: rgb(186, 213, 228);
}
/* Fonts */
:root {
    --font-fam: 'DM Mono', monospace;
}